//
// Font faces, stacks and sizes.
//
// Fira Sans is used on Android instead of TrebuchetMS
//@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,700,700i&subset=cyrillic');
//@import url('https://code.cdn.mozilla.net/fonts/fira.css');
@import "fonts";

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   14px; // The font size set on the root html element.
$base-line-height: 20px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.3  * $base-font-size;
$h3-font-size: 1.1  * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.75 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica:       Helvetica, Arial, "Nimbus Sans L", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;
$trebuchet:		  'Trebuchet MS', 'Fira Sans', Tahoma, 'Helvetica CY', Helvetica, Arial, "Nimbus Sans L", sans-serif;
$plumb:			  'Plumb', 'Trebuchet MS', 'Helvetica CY', Helvetica, Arial, "Nimbus Sans L", sans-serif;
$icomoon:			  'IcomoonFree';

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $trebuchet; // The font family set on the html element.
$font-body:        $trebuchet;
$font-monospace:   $courier;


//
// Colors, etc.
//

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;


//
// Partials to be shared with all .scss files.
//

//@import "compass/support";  // Add Compass' IE and vendor prefix support variables.
//@import "compass/utilities/general/clearfix"; // Better than Drupal's clearfix.
//@import "compass/utilities/sprites"; // See http://compass-style.org/help/tutorials/spriting/
//@import "compass/css3";     // Use one CSS3 mixin instead of multiple vendor prefixes.

@mixin appearance($appearance) {
  // There is no caniuse tracking for appearance.
  $appearance: unquote($appearance);
  -moz-appearance: $appearance;
  -webkit-appearance: $appearance;
}


//@import "compass/typography/vertical_rhythm"; // Helps set up a vertical rhythm.
@import "_grids";              // Add the Zen Grids responsive layout mixins.

@import "sass-image";

@import "custom";           // Import our custom mixins last.
