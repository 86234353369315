//@import "compass/css3/text-shadow";
//@import "compass/css3/user-interface";


// _text-shadow.scss

// These defaults make the arguments optional for this mixin
// If you like, set different defaults in your project

$default-text-shadow-color      : #aaaaaa !default;
$default-text-shadow-h-offset   : 0px     !default;
$default-text-shadow-v-offset   : 0px     !default;
$default-text-shadow-blur       : 1px     !default;
$default-text-shadow-spread     : false   !default;


// Provides cross-browser text shadows when one or more shadows are needed.
// Each shadow argument should adhere to the standard css3 syntax for the
// text-shadow property.
//
// Note: if any shadow has a spread parameter, this will cause the mixin
// to emit the shadow declaration twice, first without the spread,
// then with the spread included. This allows you to progressively
// enhance the browsers that do support the spread parameter.
@mixin text-shadow(
  $shadow...
) {
  $shadow: if(length($shadow) > 0, $shadow, default);
  $default: -compass-space-list(compact($default-text-shadow-h-offset $default-text-shadow-v-offset $default-text-shadow-blur $default-text-shadow-spread $default-text-shadow-color));
  $shadows-without-spread: join((),(),comma);
  $shadows: join((),(),comma);
  $has-spread: false;

  @each $layer in $shadow {
    $layer: if($layer == 'default', $default, $layer);
    @if length($layer) > 4 {
      $has-spread: true;
      $shadows-without-spread: append($shadows-without-spread, nth($layer,1) nth($layer,2) nth($layer,3) nth($layer,5));
      $shadows: append($shadows, $layer);
    } @else {
      $shadows-without-spread: append($shadows-without-spread, $layer);
      $shadows: append($shadows, $layer);
    }
  }
  @if $has-spread {
    text-shadow: $shadows-without-spread;
  }
  text-shadow: $shadows;
}

// Provides a single cross-browser CSS text shadow.
//
// Provides sensible defaults for the color, horizontal offset, vertical offset, blur, and spread
// according to the configuration defaults above.
@mixin single-text-shadow(
  $hoff: false,
  $voff: false,
  $blur: false,
  $spread: false,
  $color: false
) {
  // A lot of people think the color comes first. It doesn't.
  @if type-of($hoff) == color {
    $temp-color: $hoff;
    $hoff: $voff;
    $voff: $blur;
    $blur: $spread;
    $spread: $color;
    $color: $temp-color;
  }
  // Can't rely on default assignment with multiple supported argument orders.
  $hoff:   if($hoff,   $hoff,   $default-text-shadow-h-offset);
  $voff:   if($voff,   $voff,   $default-text-shadow-v-offset);
  $blur:   if($blur,   $blur,   $default-text-shadow-blur    );
  $spread: if($spread, $spread, $default-text-shadow-spread  );
  $color:  if($color,  $color,  $default-text-shadow-color   );
  // We don't need experimental support for this property.
  @if $color == none or $hoff == none {
    @include text-shadow(none);
  } @else {
    @include text-shadow(compact($hoff $voff $blur $spread $color));
  }
}

// _user-interface.scss
// User Interface
// This file can be expanded to handle all the user interface properties as
// they become available in browsers:
// http://www.w3.org/TR/2000/WD-css3-userint-20000216

// The prefixed support threshold for user-select.
$userselect-support-threshold: 0.1 !default;

// This property controls the selection model and granularity of an element.
//
// @param $select
//   [ none | text | toggle | element | elements | all | inherit ]
@mixin user-select($select) {
  $select: unquote($select);
}

// The prefixed support threshold for input-placeholder.
// Defaults to the $graceful-usage-threshold.
$input-placeholder-support-threshold: 0.1 !default;

// Style the html5 input placeholder in browsers that support it.
//
// The styles for the input placeholder are passed as mixin content
// and the selector comes from the mixin's context.
//
// For example:
//
//     #{elements-of-type(text-input)} {
//       @include input-placeholder {
//         color: #bfbfbf;
//         font-style: italic;
//       }
//     }
//
// if you want to apply the placeholder styles to all elements supporting
// the `input-placeholder` pseudo class (beware of performance impacts):
//
//     * {
//       @include input-placeholder {
//         color: #bfbfbf;
//         font-style: italic;
//       }
//     }
@mixin input-placeholder {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::placeholder { @content; }
}







@mixin jquery-text-shadow-base-styles {
  .ui-text-shadow, .ui-text-shadow-original {
    position: relative;
  }
  .ui-text-shadow-original {
    z-index: 1;
    text-shadow: none;
  }
  .ui-text-shadow-copy {
    position: absolute;
    z-index: 0;
    
    line-height: 110.74235%; // fixes #9

    // default positioning
    left: 0;
    top: 0;
    zoom: 1; // fixes #8 in IE7 and below
    
    // turn off shadow
    text-shadow: none;
    
    // turn off selection
    @include user-select(none);
  }
}

@mixin jquery-text-shadow($shadow-1: default, $shadow-2: false, $shadow-3: false, $shadow-4: false, $shadow-5: false, $shadow-6: false, $shadow-7: false, $shadow-8: false, $shadow-9: false, $shadow-10: false) {
  // for supported browers
  @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10);

  @if $shadow-1 == default {
    $shadow-1: $default-text-shadow-color $default-text-shadow-h-offset $default-text-shadow-v-offset $default-text-shadow-blur;
  }
  $shadows: ($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10);
  $i: 1;
  @each $shadow in $shadows {
    @if $shadow {
      $length: length($shadow);
      
      // some defaults
      $color: inherit;
      $opacity: 100;
      $offset-x: 0;
      $offset-y: 0;
      $blur-radius: 0;
      $spread: 0; // IE10 only, not used

      // pull apart the individual arguments from the list
      @if type-of(nth($shadow, 1)) == color {
        // color first, blur optional, spread optional
        @if $length >= 1 { $color: nth($shadow, 1); }
        @if $length >= 2 { $offset-x: nth($shadow, 2); }
        @if $length >= 3 { $offset-y: nth($shadow, 3); }
        @if $length >= 4 { $blur-radius: nth($shadow, 4); }
        @if $length == 5 { $spread: nth($shadow, 5); }
      } @else {
        // color last or missing, blur optional, spread optional
        @if type-of(nth($shadow, $length)) == color {
          $color: nth($shadow, $length);
        }
        @if $length >= 1 { $offset-x: nth($shadow, 1); }
        @if $length >= 2 { $offset-y: nth($shadow, 2); }
        @if $length >= 3 {
          @if type-of(nth($shadow, 3)) != color { $blur-radius: nth($shadow, 3); }
        }
        @if $length >= 4 {
          @if type-of(nth($shadow, 4)) != color { $spread: nth($shadow, 4); }
        }
      }

      // seperate the color from the opacity
      @if $color != inherit {
        $opacity: round(alpha($color) * 100);
        $color: rgb(red($color), green($color), blue($color));
      }
      
      // remove the unit from the radius
      @if unit($blur-radius) == px {
        $blur-radius: $blur-radius / 1px;
      }      

      // for the jquery text-shadow plugin
      .ui-text-shadow-copy-#{$i} {
        color: $color;
        filter: if( $opacity < 100, unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})"), unquote("") )
          if( $blur-radius > 0, unquote("progid:DXImageTransform.Microsoft.Blur(makeShadow=false,pixelRadius=#{$blur-radius})"), unquote("") );
        left: $offset-x - $blur-radius;
        top: $offset-y - $blur-radius;
      }
      $i: $i + 1;
    }
  }
}